import MainPage from "./pages/MainPage";
import React from 'react'

const Page = () => {
  return (
    <div>
      <MainPage />
    </div>
  )
}
export default Page;
